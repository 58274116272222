.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.full-page {
  width: 100%;
  height: 100vh;
  background-color: #008080;
  position: absolute;
  top: 0;
  left: 0;
}


.button-container {
  display: flex;
  justify-content: center; /* Horizontally centers the buttons */
  align-items: center; /* Vertically centers the buttons if the div has a height */
}

.selection-button {
  margin: 0 15px; /* Adds space between the buttons */
}



/* Centering the window with padding */
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px; /* Adjust the padding to your preference */
  box-sizing: border-box; /* Ensure padding is included in the element's total width/height */
}

.window {
  width: 400px;
}

.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 90%;
}